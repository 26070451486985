import { render, staticRenderFns } from "./ViewAccount.vue?vue&type=template&id=72de8926&scoped=true&"
import script from "./ViewAccount.vue?vue&type=script&lang=js&"
export * from "./ViewAccount.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72de8926",
  null
  
)

export default component.exports