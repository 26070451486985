import { render, staticRenderFns } from "./ReviewOrder.vue?vue&type=template&id=16d691f2&scoped=true&"
import script from "./ReviewOrder.vue?vue&type=script&lang=js&"
export * from "./ReviewOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d691f2",
  null
  
)

export default component.exports