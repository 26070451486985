<template>
  <div class="section-header" :class="field.classes != null ? field.classes : ''" v-if="field.type == 'section'">
    <span v-if="$parent.language == 'English'">{{ field.name }}</span>
    <span v-if="$parent.language == 'Spanish'">{{ field.spanishName }}</span>
  </div>

  <div class="html" :class="field.classes != null ? field.classes : ''" v-else-if="field.type == 'html'" v-html="$parent.language == 'English' ? field.html : field.spanishHtml">
  </div>

  <div class="field form-item" :class="field.classes != null ? field.classes : ''" v-else>
    <label v-if="field.name != '' && $parent.language == 'English'">{{ field.name }}<span v-if="field.required">*</span></label>

    <label v-if="field.spanishName != null && $parent.language == 'Spanish'">{{ field.spanishName }}<span v-if="field.required">*</span></label>

    <input type="text" v-model="field.value" v-if="field.type == 'text'" :key="field.slug" :required="('required' in field && field.required == true) ? true : false" :placeholder="field.placeholder" />

    <input type="text" v-mask="'##/##/####'" v-model="field.value" v-if="field.type == 'date'" :key="field.slug" :required="('required' in field && field.required == true) ? true : false" :placeholder="field.placeholder" />

    <input type="tel" v-model="field.value" v-if="field.type == 'tel'" :key="field.slug" :required="('required' in field && field.required == true) ? true : false" :placeholder="field.placeholder" v-on:keydown="formatTel" />

    <input type="email" v-model="field.value" v-if="field.type == 'email'" :key="field.slug" :required="('required' in field && field.required == true) ? true : false" />

    <datepicker v-model="field.value" v-if="field.type == 'datepicker'" :key="field.slug" :required="('required' in field && field.required == true) ? true : false"></datepicker>

    <select v-model="field.value" v-if="field.type == 'select'" :required="('required' in field && field.required == true) ? true : false">
      <option value=""></option>
      <option :value="option.value" v-for="option in field.options" v-bind:key="option.value">{{ option.name }}</option>
    </select>

    <textarea v-model="field.value" v-if="field.type == 'textarea'" :required="('required' in field && field.required == true) ? true : false"></textarea>

    <div v-if="field.type == 'repeater'">
      <div class="repeater d-flex flex-column flex-lg-row px-0" v-for="subFields in field.subFields" v-bind:key="subFields">
        <div class="sub-field form-item" :class="subField.classes != null ? subField.classes : ''" v-for="subField in subFields" v-bind:key="subField">
          <label v-if="subField.name != '' && $parent.language == 'English'">{{ subField.name }}<span v-if="subField.required">*</span></label>
          <label v-if="$parent.language == 'Spanish'">{{ subField.spanishName }}<span v-if="field.required">*</span></label>
          <input type="tel" v-model="subField.value" v-if="subField.type == 'tel'" :key="subField.slug" :required="('required' in field && field.required == true) ? true : false" :placeholder="field.placeholder" v-on:keydown="formatTel" />
          <input type="text" v-model="subField.value" v-if="subField.type == 'text' || subField.type == 'encrypted'" :key="subField.slug" :required="('required' in subField && subField.required == true) ? true : false" :placeholder="subField.placeholder" />
          <datepicker v-model="subField.value" initial-view="year" format="MM/dd/yyyy" :typeable="true" v-if="subField.type == 'datepicker'" :required="('required' in field && field.required == true) ? true : false"></datepicker>
          <textarea v-model="subField.value" v-if="subField.type == 'textarea'" :required="('required' in field && field.required == true) ? true : false"></textarea>
        </div>
      </div>
      <a class="button" v-if="$parent.language == 'English'" @click="addRepeater(field)" v-html="field.buttonText != '' ? field.buttonText : 'Add Another'"></a>
      <a class="button" v-if="$parent.language == 'Spanish'" @click="addRepeater(field)" v-html="field.spanishButtonText != null ? field.spanishButtonText : field.buttonText"></a>
    </div>

    <div class="radio-options" v-if="field.type == 'radio'">
      <div class="radio-option" v-for="option in field.options" :key="option.name">
        <input type="radio" v-model="field.value" :value="option.value" />
        <label v-if="$parent.language == 'English'">{{ option.name }}</label>
        <label v-if="$parent.language == 'Spanish'">{{ option.spanishName }}</label>
      </div>
    </div>

    <div class="radio-options" v-if="field.type == 'checkbox'">
      <div class="radio-option" v-for="option in field.options" :key="option.name">
        <input type="checkbox" v-model="field.value" :value="option.value" />
        <label v-if="$parent.language == 'English'">{{ option.name }}</label>
        <label v-if="$parent.language == 'Spanish'">{{ option.spanishName }}</label>
      </div>
    </div>

    <div class="description mt-3 font-italic text-0" v-if="'description' in field">{{ field.description }}</div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'Field',
  components: {
    Datepicker
  },
  props: [
    'field'
  ],
  data() {
    return {
      photoMode: 'upload',
      hasCamera: false,
      cameraOpen: false,
      video: {},
      canvas: {},
      capture: null,
      captureSize: {
        width: null,
        height: null
      }
    };
  },
  methods: {
    addRepeater(field) {
      field.subFields.push(JSON.parse(JSON.stringify(field.subFieldTemplate)));
    },
    removeRepeater(subFieldIndex) {
      this.field.subFields.splice(subFieldIndex, 1);
    },
    formatTel(event) {
      if (!this.field.value || (event.code == 'Delete' || event.code == 'Backspace' || event.code == 'Tab')) return;

      // clean the input for any non-digit values.
      const phoneNumber = this.field.value.replace(/[^\d]/g, "");

      // phoneNumberLength is used to know when to apply our formatting for the phone number
      const phoneNumberLength = phoneNumber.length;

      // we need to return the value with no formatting if its less then four digits
      // this is to avoid weird behavior that occurs if you  format the area code to early
      if (phoneNumberLength < 4) return phoneNumber;

      // if phoneNumberLength is greater than 4 and less the 7 we start to return
      // the formatted number
      if (phoneNumberLength < 7) {
        this.field.value = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }

      // finally, if the phoneNumberLength is greater then seven, we add the last
      // bit of formatting and return it.
      this.field.value = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 9)}`;
    },
    updateRepeaters() {
      this.$parent.fields.forEach(field => {
        if (field.type == 'numeric-repeater') {
          let numberField = this.fields.find(field2 => field2.slug == field.numberField);
          if (field.subFields.length == numberField.value) return false;
          field.subFields = [];
          for (let i=1; i <= parseInt(numberField.value); i++) {
            let newArray = JSON.parse(JSON.stringify(field.subFieldTemplate));
            field.subFields.push(newArray);
          }
        }
      });
    },
    updateFields() {
      this.updateRepeaters();
    }
  },
  mounted() {
    this.updateFields();
  }
}
</script>

<style lang="scss" scoped>
.fake-hidden {
position: absolute;
z-index: -1;
left: -9999%;
}
</style>