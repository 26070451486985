<template>
  <div id="account">
    <section id="account-chooser" class="mx-0" v-if="show == 'account-chooser'">
      <div class="content p-4 d-flex align-items-center flex-column flex-lg-row gap-3">
        <div class="col-12 col-lg-6 text-center mb-3 mb-lg-0">
          <div class="box-header mb-5">Login</div>
          <h4 class="sohne-breit mb-3">Have An Account?</h4>
          <button class="button red-bg login" @click="setShow('login')">Login</button>
        </div>
        <div class="col-12 col-lg-6 text-center">
          <div class="box-header mb-5">Register</div>
          <h4 class="sohne-breit mb-3">Don't have an account?</h4>
          <button class="button register" @click="setShow('register')">Create Account</button>
        </div>
      </div>
    </section>
    <section id="login" class="mx-0 mx-lg-5" v-if="show == 'login'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6 mx-auto">
            <div class="box-header">Login</div>
            <div class="content py-3 px-0 d-flex align-items-center justify-content-center">
              <form class="form flex-column" @submit.prevent="login()">
                <div class="form-item">
                  <label>Email</label>
                  <input class="email" type="text" v-model="username" placeholder="Email" />
                </div>
                <div class="form-item">
                  <label>Password</label>
                  <input class="password" type="password" v-model="password" placeholder="Password" />
                  <div class="description mt-1">Can't remember your password? <a href="/reset-password/" target="_blank" class="font-weight-bold">Click here to reset your password.</a></div>
                </div>
                <div class="form-item">
                  <button class="button login" type="submit">Log In</button>
                </div>
                <div class="form-item text-center mt-3 font-weight-bold"><a @click="setShow('register')">Don't have an account? Register now.</a></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="register-account" class="mx-0 mx-lg-5" v-if="show == 'register'">
      <div class="box-header">Register Account</div>
      <div class="content p-4 d-flex align-items-center">
        <form ref="registerform" class="form" @submit.prevent="register()">
          <div class="form-item mt-2">
            <label>Is your company an existing Oregon Fruit customer?</label>
            <div class="d-flex gap-2 align-items-center justify-content-start"><input type="radio" name="existing-customer" v-model="existingCustomer" value="yes" required /> Yes <input type="radio" name="existing-customer" v-model="existingCustomer" value="no" required /> No</div>
          </div>
          <div class="form-item" v-if="existingCustomer == 'yes'">
            <label>Do you have an account ID to link your existing account?</label>
            <div class="d-flex gap-2 align-items-center justify-content-start"><input type="radio" name="has-nav-id" v-model="hasNavId" value="yes" required /> Yes <input type="radio" name="has-nav-id" v-model="hasNavId" value="no" required /> No</div>
          </div>
          <div class="form-item half" v-if="hasNavId == 'yes'">
            <input type="text" v-model="newAccount.navId" placeholder="Account Number" required />
          </div>
          <div class="form-item" v-if="existingCustomer == 'yes' && hasNavId == 'no'">
            <p>Existing Oregon Fruit customers without an OregonFruit.com account must <a href="/contact" target="_blank">contact us</a> to get their account number before account creation.</p>
          </div>
          <div class="d-flex flex-wrap justify-content-between" v-if="existingCustomer == 'no' || (existingCustomer == 'yes' && hasNavId == 'yes')">
            <h4 class="form-item ml-1">Customer Type</h4>
            <div class="form-item">
              <select v-model="newAccount.customerType" required>
                <option value="">How will you use your purees?</option>
                <option value="BREW">Brewing/Fermentation</option>
                <option value="III">Food &amp; Beverage Manufacturing</option>
              </select>
            </div>
            <h4 class="form-item ml-1" v-if="newAccount.customerType == 'BREW'">Brewer Information</h4>
            <div class="form-item" v-if="newAccount.customerType == 'BREW'">
              <select v-model="brewerType" required>
                <option value="">What type of brewer are you?</option>
                <option value="traditional">Traditional</option>
                <option value="homebrewer">Homebrewer</option>
              </select>
            </div>
            <div class="form-item" v-if="newAccount.customerType == 'BREW' && brewerType == 'traditional'">
              <input type="text" v-model="newAccount.ttbNumber" placeholder="TTB Number" required />
            </div>
            <div class="form-item" v-if="newAccount.customerType == 'BREW' && brewerType == 'homebrewer'">
              <input type="text" v-model="newAccount.resellersPermitNumber" placeholder="Resellers Permit Number" required />
            </div>
            <h4 class="form-item ml-1">Contact Info</h4>
            <div class="form-item">
              <input type="text" v-model="newAccount.company" placeholder="Company" required />
            </div>
            <div class="form-item half">
              <input type="text" v-model="newAccount.firstName" placeholder="First Name" required />
            </div>
            <div class="form-item half">
              <input type="text" v-model="newAccount.lastName" placeholder="Last Name" required />
            </div>
            <div class="form-item half">
              <input type="text" v-model="newAccount.email" placeholder="Email" required />
            </div>
            <div class="form-item half">
              <input type="text" minlength="14" v-model="newAccount.phone" placeholder="Phone" required />
            </div>
            <h4 class="form-item mt-2 ml-1">Address</h4>
            <div class="form-item">
              <select v-model="newAccount.address.country" required>
                <option value="">Choose Country</option>
                <option value="US">United States</option>
                <option value="CA">Canada</option>
              </select>
            </div>
            <div class="form-item">
              <input type="text" v-model="newAccount.address.address1" placeholder="Address" required />
            </div>
            <div class="form-item half">
              <input type="text" v-model="newAccount.address.city" placeholder="City" required />
            </div>
            <div class="form-item half" v-if="newAccount.address.country == 'US'">
              <select v-model="newAccount.address.state" required>
                <option value="">Choose State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="GU">Guam</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="PR">Puerto Rico</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VI">Virgin Islands</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div class="form-item half" v-if="newAccount.address.country == 'CA'">
              <select v-model="newAccount.address.state" required>
                <option value="">Choose Province</option>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NS">Nova Scotia</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="NT">Northwest Territories</option>
                <option value="NU">Nunavut</option>
                <option value="YT">Yukon</option>
              </select>
            </div>
            <div class="form-item half">
              <input type="text" v-model="newAccount.address.zipcode" :placeholder="newAccount.address.country == 'CA' ? 'Postal Code' : '5 Digit Zipcode (xxxxxx)'" required />
            </div>
            <h4 class="form-item mt-2 ml-1">Password</h4>
            <div class="form-item half">
              <input type="password" v-model="newAccount.password" placeholder="Password" minlength="6" required />
              <div class="description">Your password must be at least 6 characters long, have at least one uppercase and one lowercase letter, and have at least one number.</div>
            </div>
            <div class="form-item half">
              <input type="password" v-model="newAccount.passwordConfirm" placeholder="Password Confirm" minlength="6" required />
            </div>
            <div class="form-item mt-2">
              <button class="button" type="submit" v-if="canCreateAccount && submitting == false">Create Account</button>
            </div>
          </div>
          <div class="form-item text-center mt-3 font-weight-bold"><a @click="setShow('login')">Already have an account? Click here to login.</a></div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import Api from '@/models/Api.js'
import { formatPhone } from '@/helpers.js'

export default {
  name: 'Account',
  data() {
    return {
      username: '',
      password: '',
      newAccount: {
        password: '',
        passwordConfirm: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        navId: '',
        customerType: '',
        address: {
          address1: '',
          city: '',
          state: '',
          zipcode: '',
          country: ''
        },
        ttbNumber: '',
        resellersPermitNumber: ''
      },
      existingCustomer: null,
      hasNavId: null,
      brewerType: '',
      show: 'account-chooser',
      submitting: false
    };
  },
  computed: {
    canCreateAccount() {
      if (this.existingCustomer == 'yes' && this.hasNavId == 'no') {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    'newAccount.phone': function(newVal) {
      if (newVal != null) {
        this.newAccount.phone = formatPhone(newVal);
      }
    }
  },
  methods: {
    login() {
      return Api.login(this.username, this.password).then(response => {
        if (response.success == true) {
          Api.getAuth('account/'+response.data.id+'?extra=1', response.data.token).then(accountResponse => {
            if (!accountResponse.hasOwnProperty('message')) {
              let userData = { ...response.data, ...accountResponse };
              this.$store.commit('setUserData', userData);
              this.$store.commit('setOrderInfo', { key: 'deliveryAvailability', value: userData.deliveryAvailability });
              if (this.$store.state.cartItems.length > 0) {
                this.$store.commit('changeOrderStep', 'shipping');
              } else {
                this.$store.commit('changeOrderStep', 'products');
              }
              this.$overlay(true, 'Welcome back! Please wait while we load fresh purees.', 2000);
              this.$store.commit('getPreviousOrders');
              return response.data;
            } else {
              this.$overlay(true, '<span class="text-0 red-links">'+accountResponse.message+'</span>', 3000);
            }
          });
        } else {
          this.$overlay(true, response.message, 3000);
        }
      });
    },
    register() {
      // Make sure form is validate before proceeding
			if (!this.$refs.registerform.checkValidity()) {
				this.$refs.registerform.reportValidity();
        return false;
      }

      if (this.newAccount.password != this.newAccount.passwordConfirm) {
        this.$overlay(true, 'Your password and confirmation password do not match.', 2000);
        return false;
      }

      if (this.newAccount.password.length < 6) {
        this.$overlay(true, 'Your password is not long enough.', 2000);
        return false;
      }

      if (this.newAccount.password.search(/\d/) == -1) {
        this.$overlay(true, 'Your password does not contain a number.', 2000);
        return false;
      }

      if (this.newAccount.password.search(/[A-Z]/) == -1) {
        this.$overlay(true, 'Your password does not contain an uppercase letter.', 2000);
        return false;
      }

      if (this.newAccount.password.search(/[a-z]/) == -1) {
        this.$overlay(true, 'Your password does not contain an lowercase letter.', 2000);
        return false;
      }

      this.submitting = true;

      Api.post('account', this.newAccount).then(response => {
        if (response.success == true) {
          this.username = this.newAccount.email;
          this.password = this.newAccount.password;
          this.login().then(response => {
            Api.postAuth('account/address', { ...this.newAccount.address, type: 'billing', customer_id: response.id, company: this.newAccount.company, name: this.newAccount.firstName+' '+this.newAccount.lastName }, this.$store.state.userData.token).then(() => {
              this.$overlay(true, 'Your account was successfully created.', 3000);
            });
          });
        } else {
          this.submitting = false;
          let message = '';
          Object.keys(response.message).forEach(key => {
            message += response.message[key];
          });
          this.$overlay(true, message, 2000);
        }
      });
    },
    setShow(newShow) {
      this.show = newShow;
    }
  },
  mounted() {
    if (this.$store.state.userData != null) {
      this.$store.commit('changeOrderStep', 'shipping');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>