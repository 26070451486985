<template>
  <div id="employment-application">
    <h1 class="text-center font-weight-bold mb-2">Employment Application</h1>
    <div class="language-chooser" v-if="formDone != true">
      <label class="text-center mb-2">Choose Your Language</label>
      <a @click="setLanguage('English')" :class="{ 'selected': language == 'English' }">English</a>
      <a @click="setLanguage('Spanish')" :class="{ 'selected': language == 'Spanish' }">Spanish</a>
    </div>
    <form ref="form" id="volunteer-form" class="form" enctype="multipart/form-data" @submit.prevent="" v-if="formDone != true">
      <div id="success" class="border p-3 text-center font-weight-bold mb-3" v-if="formDone == true && errors == null">Thanks again! We'll contact you soon to follow up.</div>
      <div id="errors" class="border p-3 text-center font-weight-bold mb-3" v-if="errors != null">There was an error submitting your application: {{ errors }}</div>
      <Field v-for="field in fields" v-bind:key="field.slug" :field="field"></Field>
      <div class="form-item buttons">
        <input type="submit" :value="language == 'Spanish' ? 'Presentar la Solicitud' : 'Submit Application'" class="button mr-2" v-if="disableSubmit != true" @click="submitForm()" />
      </div>
    </form>
  </div>
</template>

<script>
import Api from '@/models/Api.js'
import Field from '@/components/Field'
import FieldData from './FieldData.js'

export default {
  name: 'EmploymentApplication',
  components: {
    Field
  },
  data() {
    return {
      formSuccess: false,
      formDone: false,
      disableSubmit: false,
      errors: null,
      files: null,
      fileUrl: '',
      fields: FieldData,
      language: 'English'
    };
  },
  methods: {
    setLanguage(language) {
      this.language = language;
    },
    getFileUrl(file) {
      return URL.createObjectURL(file);
    },
    filterResetFields() {
      return this.fields.filter(field => field.keepOnReset != true);
    },
    resetFieldsForMore() {
      this.filterResetFields().forEach(field => {
        field.value = '';
      });
    },
    submitForm() {
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return false;
      }

      this.doSubmitForm();
      // this.recaptcha().then(token => {
      //   console.log(token);
      //   this.doSubmitForm(addMore);
      // });
    },
    doSubmitForm() {
      this.$overlay(true, '<img src="/wp-content/uploads/2020/10/strawberry-icon.png" class="loading-icon mb-2" /><div>One moment while we transmit your application.</div>', 4000);

      this.disableSubmit = true;

      let formData = new FormData();
      let fields = JSON.stringify(this.fields);

      formData.append('fields', fields);

      this.errors = null;

      Api.post(
        'application',
        formData,
        {
          'Content-Type': 'multipart/form-data'
        }
      ).then(response => {
        this.disableSubmit = false;
        if (response.success == true) {
          this.formSuccess = true;
          this.formDone = true;
          this.$overlay(true, '<div>Success! We have received your application. We will be in touch with you soon.</div>', 3000);
        } else {
          this.$overlay(true, '<div>We did not successfully receive your application. Please try again soon. We are sorry for the inconvenience.</div>', 3000);
          this.formSuccess = false;
          this.errors = response.message;
          setTimeout(() => {
            document.querySelector("#errors").scrollIntoView({
              behavior: 'smooth'
            });
          }, 500);
        }
      });
    },
    // async recaptcha() {
    //   // (optional) Wait until recaptcha has been loaded.
    //   await this.$recaptchaLoaded()

    //   // Execute reCAPTCHA with action "login".
    //   const token = await this.$recaptcha('login')

    //   // Do stuff with the received token.
    //   return token;
    // }
  },
  mounted() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.language != undefined) {
      this.setLanguage(params.language);
    }
    this.filterResetFields();
  }
}
</script>

<style lang="scss">
#employment-application {
width: 100%;
padding: 3rem 5%;
max-width: 1000px;
margin: auto;

  #success {
  border: 1px solid #ddd;
  box-shadow: 0 0 5px rgba(0,0,0,.2);
  padding: 3rem;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #000;
  font-size: 1rem;
  width: 100%;
  margin: 2rem 0px;
  }

  .language-chooser {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 0px;

    label {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    }

    a {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: .75rem;
    transition: all .5s ease;
    margin: 0px .5rem;
    cursor: pointer;

      &.selected {
      background: #445FC0;
      color: #fff;
      transition: all .5s ease;
      }

    }

  }

  .section-header {
  display: block;
  width: 100%;
  padding: 1.5rem 2rem;
  margin: 1.5rem 0px;
  color: #fff;
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
  background: #000;
  }

  .repeater {
  flex-wrap: wrap;
  padding: 1rem 0px;
  margin: 2rem 0px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

    label {
    font-size: .9rem;
    }

  }

  .button {
  font-size: 16px !important;
  text-transform: uppercase !important;
  color: #000000 !important;
  background-color: #FFFFFF00 !important;
  border-style: solid !important;
  border-width: 1px 1px 1px 1px !important;
  border-color: #000000 !important;
  border-radius: 0px 0px 0px 0px !important;
  padding: 20px 20px 20px 20px !important;
  }

  .form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

    .form-item {
    width: 98%;
    margin: 1em 1%;

      @media (max-width: 991px) {
      width: 98% !important;
      }
      
      &.half {
      width: 48%;
      margin: .75em 1%;
      }

      &.third {
      width: 31%;
      margin: .75em 1%;
      }

      &.fourth {
      width: 22%;
      margin: .75em 1%;
      }

      label {
      display: block;
      margin-bottom: .25rem !important;
      font-size: 1.1rem;
      }

      input:not([type="submit"]), select {
      display: inline-block;
      width: 100%;
      border: 1px solid #ddd;
      padding: .5rem .5rem !important;
      background: #fefefe;
      color: #333;
      }

      textarea {
      width: 100%;
      min-height: 140px;
      }

      .radio-options {
      display: flex;
      align-items: center;

        .radio-option {
        display: flex;
        align-items: center;
        margin-right: 2rem;
        width: 25%;

          input {
          width: 25px;
          }

          label {
          margin: 0 0 0 1rem !important;
          padding: 0px;
          color: #333;
          font-weight: 400;
          }

        }

      }
      
    }

    .photo-form-item {
    margin: 2em 1% !important;
    }

    [type='submit'] {
    padding: 1.75rem 3rem;
    }

    .buttons {
    justify-content: center;

      .button {
      margin: 0px;
      width: 100%;
      }

    }

  }

  .popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.25);

    .message {
    width: 400px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 5px rgba(0,0,0,.2);
    border-radius: 10px;
    padding: 3rem;
    font-weight: bold;
    color: #0079c2;
    font-size: 1.15em;
    background: #fff;
    text-align: center;
    }
  
  }

}

</style>
