<template>
  <section id="foodservice-products" class="section">
    <div class="container-fluid">
      <div class="row mb-3" v-if="currentTypeObject">
        <div class="col-12 col-lg-2"></div>
        <div class="col-12 col-lg-8 mx-auto text-center">
          <h3 class="gold">{{ currentTypeObject.name }}</h3>
          <p class="text-2">{{ currentTypeObject.description }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-2">
          <a class="sidebar-filter-link large" @click="setCurrentType(null)" :class="{ 'selected': currentType == null }">All</a>
          <a class="sidebar-filter-link large" v-for="type in types" :key="type.term_id" @click="setCurrentType(type.slug)" :class="{ 'selected': currentType == type.slug }">{{ type.name }}</a>
        </div>
        <div class="col-12 col-lg-10">
          <div class="row match-parent" v-if="products != false">
            <div class="col-12 col-md-6 col-xl-4" v-for="product in products" v-bind:key="product['ID']">
              <div class="box mb-3">
                <a class="image-wrapper" :href="product.permalink">
                  <div class="image" :style="'background: url('+product.image+') no-repeat center center;'"></div>
                </a>
                <div class="content match">
                  <div class="top">
                    <p class="above mb-0" v-if="typeof product.fruits[0] !== 'undefined'">{{ product.fruits[0].name }}</p>
                    <h4 class="headline"><a :href="product.permalink">{{ product.post_title }}</a></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12">
              <p class="message">We couldn't find any products for your filters. Adjust your filters and try again.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Api from '@/models/Api.js'
import { queryString } from '@/helpers.js'

export default {
  name: 'FoodserviceProducts',
  data() {
    return {
      products: false,
      productsFiltered: [],
      types: [],
      fruits: [],
      currentType: null,
      currentFruit: null,
      currentTypeObject: null
    };
  },
  methods: {
    getProducts(queryString) {
      let url = 'foodservice-products';
      if (queryString) {
        url += queryString;
      }
      Api.get(url).then(response => {
        this.products = response;
        setTimeout(function() { window.dispatchEvent(new Event('resize')); }, 250);
      });
    },
    getFilteredProducts() {
      this.getProducts(queryString({ type: (this.currentType ? this.currentType : ''), fruit: (this.currentFruit ? this.currentFruit : '') }));
    },
    getTypes() {
      Api.get('foodservice-product/types').then(response => {
        this.types = response;
      });
    },
    getFruits() {
      Api.get('foodservice-product/fruits').then(response => {
        this.fruits = response;
      });
    },
    setCurrentType(type) {
      this.currentType = type;
      this.getFilteredProducts();
      if (type != null) {
        Api.get('foodservice-product/type/'+type).then(response => {
          this.currentTypeObject = response;
        });
      } else {
        this.currentTypeObject = null;
      }
    },
    setCurrentFruit(fruit) {
      this.currentFruit = fruit;
      this.getFilteredProducts();
    }
  },
  mounted() {
    this.getTypes();
    this.getFruits();

    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('type') != null) {
      this.setCurrentType(urlParams.get('type'));
    }
    if (urlParams.get('fruit') != null) {
      this.setCurrentFruit(urlParams.get('fruit'));
    }
    this.getFilteredProducts();
  }
}
</script>