<template>
  <section>
    <div class="recipes-container">
      <div class="sidebar">
        <input type="text" v-model="searchTerm" placeholder="Search Recipes" class="search w-100 mb-3" @keyup="search" />

        <h5 class="sidebar-filter-headline mb-2">Filter By Fruit</h5>

        <div class="sidebar-filter-links mb-3">
          <a class="sidebar-filter-link" @click="setCurrentFruit([])" :class="{ 'selected': currentFruits.length == 0 }">All</a>
          <a class="sidebar-filter-link" v-for="fruit in fruits" v-bind:key="fruit.term_id" @click="setCurrentFruit(fruit.slug)" :class="{ 'selected': currentFruits.includes(fruit.slug) }">{{ fruit.name }}</a>
          <a class="sidebar-filter-link font-weight-bold" @click="setCurrentFruit([])" v-if="currentFruits.length > 0">CLEAR</a>
        </div>

        <!-- <h5 class="sidebar-filter-headline mb-2">Filter By Category</h5>
        
        <div class="sidebar-filter-links mb-4">
          <a class="sidebar-filter-link" @click="setCurrentCategory([])" :class="{ 'selected': currentCategories.length == 0 }">All</a>
          <a class="sidebar-filter-link" v-for="category in categories" v-bind:key="category.slug" @click="setCurrentCategory(category.slug)" :class="{ 'selected': currentCategories.includes(category.slug) }">{{ category.name }}</a>
          <a class="sidebar-filter-link font-weight-bold" @click="setCurrentCategory([])" v-if="currentCategories.length > 0">CLEAR</a>
        </div>
        -->
      </div>
      <div class="main">
        <div class="recipes" v-if="recipes != false">
          <div class="recipe-item box" v-for="recipe in recipes" v-bind:key="recipe['ID']">
            <a class="image-wrapper" :href="recipe.permalink">
              <div class="image" :data-bg="'url('+recipe.image+') no-repeat center center'"></div>
            </a>
            <div class="content">
              <div class="top">
                <p class="above mb-0" v-if="typeof recipe.fruits[0] !== 'undefined'">{{ recipe.fruits[0].name }}</p>
                <h4 class="headline"><a :href="recipe.permalink">{{ recipe.post_title }}</a></h4>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="message">We couldn't find any recipes for your filters. Adjust your filters and try again.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Api from '@/models/Api.js'
import { queryString } from '@/helpers.js'

export default {
  name: 'Recipes',
  data() {
    return {
      recipes: false,
      recipesFiltered: [],
      categories: [],
      fruits: [],
      currentCategories: [],
      currentFruits: [],
      searchTerm: '',
      searchTimer: null
    };
  },
  methods: {
    getRecipes(queryString) {
      let url = 'recipes';
      if (queryString) {
        url += queryString;
      }
      Api.get(url).then(response => {
        this.recipes = response;
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
          this.onScroll();
        }, 250);
      });
    },
    getFilteredRecipes() {
      this.getRecipes(queryString({ categories: (this.currentCategories ? this.currentCategories : ''), fruits: (this.currentFruits ? this.currentFruits : ''), s: this.searchTerm }));
    },
    search() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(this.getFilteredRecipes, 1000);
    },
    getCategories() {
      Api.get('recipe/categories').then(response => {
        Object.values(response).sort((a, b) => (a.name > b.name) ? 1 : -1)
        this.categories = response;
      });
    },
    getFruits() {
      Api.get('recipe/fruits').then(response => {
        Object.values(response).sort((a, b) => (a.name > b.name) ? 1 : -1)
        this.fruits = response;
      });
    },
    setCurrentCategory(category) {
      if (this.currentCategories.includes(category)) {
        this.$delete(this.currentCategories, this.currentCategories.indexOf(category));
      } else if (category.length == 0) {
        this.currentCategories = [];
      } else {
        this.currentCategories.push(category);
      }
      this.getFilteredRecipes();
    },
    setCurrentFruit(fruit) {
      if (this.currentFruits.includes(fruit)) {
        this.$delete(this.currentFruits, this.currentFruits.indexOf(fruit));
      } else if (fruit.length == 0) {
        this.currentFruits = [];
      } else {
        this.currentFruits.push(fruit);
      }
      this.getFilteredRecipes();
    },
    isInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight + 700 || document.documentElement.clientHeight + 700) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    onScroll() {
      let images = document.querySelectorAll(".image");
      images.forEach((image) => {
        if (this.isInViewport(image) && image.style.background == '') {
          console.log('in view');
          image.style.background = image.dataset.bg;
        }
      });
    }
  },
  created() {
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll);
  },
  mounted() {
    this.getRecipes();
    this.getCategories();
    this.getFruits();
  }
}
</script>