<template>
  <section>
    <div class="container-fluid full">
      <div class="row">
        <div class="col-12 col-lg-2">
          <input type="text" v-model="searchTerm" placeholder="Search Inspirations" class="search w-100 mb-3" @keyup="search" />
          <h5 class="sidebar-filter-headline gold mb-2">Filter By Fruit</h5>
          <div class="sidebar-filter-links mb-3">
            <a class="sidebar-filter-link" @click="setCurrentFruit([])" :class="{ 'selected': currentFruits.length == 0 }">All</a>
            <a class="sidebar-filter-link" v-for="fruit in fruits" v-bind:key="fruit.term_id" @click="setCurrentFruit(fruit.slug)" :class="{ 'selected': currentFruits.includes(fruit.slug) }">{{ fruit.name }}</a>
            <a class="sidebar-filter-link font-weight-bold" @click="setCurrentFruit([])" v-if="currentFruits.length > 0">CLEAR</a>
          </div>
          <h5 class="sidebar-filter-headline gold mb-2">Filter By Category</h5>
          <div class="sidebar-filter-links mb-4">
            <a class="sidebar-filter-link" @click="setCurrentCategory([])" :class="{ 'selected': currentCategories.length == 0 }">All</a>
            <a class="sidebar-filter-link" v-for="category in categories" v-bind:key="category.slug" @click="setCurrentCategory(category.slug)" :class="{ 'selected': currentCategories.includes(category.slug) }">{{ category.name }}</a>
            <a class="sidebar-filter-link font-weight-bold" @click="setCurrentCategory([])" v-if="currentCategories.length > 0">CLEAR</a>
          </div>
        </div>
        <div class="col-12 col-lg-10">
          <div class="row match-parent mt-3 mt-lg-0" v-if="inspirations != false">
            <div class="col-12 col-md-6 col-xl-4" v-for="inspiration in inspirations" v-bind:key="inspiration['ID']">
              <div class="inspiration-item box mb-3" :class="{ 'link': inspiration.instructions != null }">
                <a class="image-wrapper" :href="inspiration.instructions != null ? inspiration.permalink : false">
                  <div class="image" :data-bg="'url('+inspiration.image+') no-repeat center center'"></div>
                </a>
                <div class="content match">
                  <div class="top">
                    <p class="above mb-0" v-if="inspiration.fruits != false">{{ inspiration.fruits[0].name }}</p>
                    <h4 class="headline"><a :href="inspiration.instructions != null ? inspiration.permalink : false">{{ inspiration.post_title }}</a></h4>
                    <p v-if="typeof inspiration.product.name !== 'undefined'">Using Fruit In Hand {{ inspiration.product.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12">
              <p class="message">We couldn't find any inspirations for your filters. Adjust your filters and try again.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Api from '@/models/Api.js'
import { queryString } from '@/helpers.js'

export default {
  name: 'Inspirations',
  data() {
    return {
      inspirations: false,
      inspirationsFiltered: [],
      categories: [],
      fruits: [],
      currentCategories: [],
      currentFruits: [],
      searchTerm: '',
      searchTimer: null
    };
  },
  methods: {
    getInspirations(queryString) {
      let url = 'inspirations';
      if (queryString) {
        url += queryString;
      }
      Api.get(url).then(response => {
        this.inspirations = response;
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
          this.onScroll();
        }, 250);
      });
    },
    getFilteredInspirations() {
      this.getInspirations(queryString({ categories: (this.currentCategories ? this.currentCategories : ''), fruits: (this.currentFruits ? this.currentFruits : ''), s: this.searchTerm }));
    },
    search() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(this.getFilteredInspirations, 1000);
    },
    getCategories() {
      Api.get('inspiration/categories').then(response => {
        this.categories = response;
      });
    },
    getFruits() {
      Api.get('inspiration/fruits').then(response => {
        this.fruits = response;
      });
    },
    setCurrentCategory(category) {
      if (this.currentCategories.includes(category)) {
        this.$delete(this.currentCategories, this.currentCategories.indexOf(category));
      } else if (category.length == 0) {
        this.currentCategories = [];
      } else {
        this.currentCategories.push(category);
      }
      this.getFilteredInspirations();
    },
    setCurrentFruit(fruit) {
      if (this.currentFruits.includes(fruit)) {
        this.$delete(this.currentFruits, this.currentFruits.indexOf(fruit));
      } else if (fruit.length == 0) {
        this.currentFruits = [];
      } else {
        this.currentFruits.push(fruit);
      }
      this.getFilteredInspirations();
    },
    isInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight + 1200 || document.documentElement.clientHeight + 1200) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    onScroll() {
      let images = document.querySelectorAll(".image");
      images.forEach((image) => {
        if (this.isInViewport(image) && image.style.background == '') {
          console.log('in view');
          image.style.background = image.dataset.bg;
        }
      });
    }
  },
  created() {
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll);
  },
  mounted() {
    this.getCategories();
    this.getFruits();

    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('category') != null) {
      this.setCurrentCategory(urlParams.get('category'));
    }
    if (urlParams.get('fruit') != null) {
      this.setCurrentFruit(urlParams.get('fruit'));
    }
    this.getFilteredInspirations();
  }
}
</script>