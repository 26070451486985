import "@babel/polyfill"
import Vue from 'vue'
// import App from './App.vue'
import Recipes from '@/components/Recipes.vue'
import Inspirations from '@/components/Inspirations.vue'
import FoodserviceProducts from '@/components/FoodserviceProducts.vue'
import FermentationProducts from '@/components/FermentationProducts.vue'
import EmploymentApplication from '@/components/EmploymentApplication.vue'
import VueOverlay from '@/components/plugins/overlay'
import VueConfirmDialog from 'vue-confirm-dialog'
import LoadScript from 'vue-plugin-load-script';
import store from './store'

Vue.use(VueOverlay);
Vue.use(VueConfirmDialog);
Vue.use(LoadScript);

Vue.config.productionTip = true;
Vue.config.devtools = true;

if (document.querySelector("#recipes")) {
  new Vue({
    render: h => h(Recipes)
  }).$mount('#recipes');
}

if (document.querySelector("#inspirations")) {
  new Vue({
    render: h => h(Inspirations)
  }).$mount('#inspirations');
}

if (document.querySelector("#foodservice-products")) {
  new Vue({
    render: h => h(FoodserviceProducts)
  }).$mount('#foodservice-products');
}

if (document.querySelector("#fermentation-products")) {
  new Vue({
    store,
    render: h => h(FermentationProducts)
  }).$mount('#fermentation-products');
}

if (document.querySelector("#employment-application")) {
  new Vue({
    render: h => h(EmploymentApplication)
  }).$mount('#employment-application');
}