<template>
  <section id="fermentation-products">
    <vue-confirm-dialog></vue-confirm-dialog>

    <MobileCart></MobileCart>

    <div class="page-section top-bar">
      <div class="tabs main" v-if="$store.state.orderStep == 'products'">
        <a class="black" v-bind:class="{ 'selected': $store.state.productsCurrentTab == 'purees' }" @click="$store.commit('setProductsCurrentTab', 'purees')">Purees</a>
        <!-- <a class="black" v-bind:class="{ 'selected': $store.state.productsCurrentTab == 'organicPurees' }" @click="$store.commit('setProductsCurrentTab', 'organicPurees')">Organic Purees</a> -->
        <a class="black" v-bind:class="{ 'selected': $store.state.productsCurrentTab == 'concentrates' }" @click="$store.commit('setProductsCurrentTab', 'concentrates')">Concentrates</a>
        <a class="black" v-bind:class="{ 'selected': $store.state.productsCurrentTab == 'samples' }" @click="$store.commit('setProductsCurrentTab', 'samples')">Samples</a>
        <a class="black" v-bind:class="{ 'selected': $store.state.productsCurrentTab == 'gear' }" @click="$store.commit('setProductsCurrentTab', 'gear')">Taprooms</a>
      </div>
      <div class="tabs main" v-else>
        <a class="d-lg-inline-block text-center" v-for="(orderStep) in $store.state.orderSteps" v-bind:key="orderStep" @click="changeOrderStep(orderStep)" v-bind:class="{ 'selected': $store.state.orderStep == orderStep }">{{ orderStep }}</a>
      </div>
      <div class="d-flex justify-content-end text-lg-right sidebar">
        <div class="user-box p-2 border text-center w-100" v-if="$store.state.userData != null">
          <span class="text-1 d-lg-block">{{ $store.state.userData.displayName }}</span>
          <a class="text-0" @click="$store.commit('changeOrderStep', 'view-account')">Account</a><span class="d-none d-lg-inline">|</span><a class="text-0" @click="$store.commit('changeOrderStep', { step: 'view-account', data: { tab: 'order-history' }})">Orders</a><span class="d-none d-lg-inline">|</span><a  class="text-0" @click="$store.commit('logout')">Logout</a>
        </div>
        <div class="" v-else>
          <a @click="$store.commit('changeOrderStep', 'account')">
            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1297_925)">
              <path d="M16.65 0C17.884 0 19.1161 0 20.35 0C20.5535 0.05735 20.7533 0.1332 20.9605 0.1665C28.5955 1.44485 33.6608 5.7683 36.1675 13.0703C36.5634 14.2228 36.7281 15.4549 37 16.65V20.35C36.9315 20.6479 36.852 20.9439 36.7984 21.2436C35.4812 28.5455 31.3279 33.4832 24.3719 36.0214C23.0843 36.4913 21.6931 36.6818 20.35 37H16.65C16.4132 36.9408 16.1783 36.8594 15.9378 36.828C8.62655 35.8234 2.60295 30.5324 0.6808 23.3988C0.4107 22.3943 0.22385 21.3675 0 20.35C0 19.055 0 17.76 0 16.465C0.0555 16.2929 0.13505 16.1246 0.1628 15.9488C1.3283 8.2399 6.7747 2.28845 14.3246 0.48655C15.0942 0.3034 15.8749 0.16095 16.65 0ZM31.2761 28.2347C35.2684 23.8391 36.8354 13.9046 29.8405 6.993C23.3137 0.5402 12.6484 0.8399 6.5342 7.6294C0.10545 14.7686 2.0054 24.3626 5.8386 28.3365C5.93295 28.17 6.0236 28.0108 6.1124 27.8499C8.0105 24.3534 10.9446 22.3628 14.9054 22.1149C17.39 21.9595 19.8967 22.0317 22.3887 22.1334C24.9694 22.2388 27.1395 23.3988 28.91 25.2303C29.7943 26.1461 30.4991 27.2339 31.278 28.2329L31.2761 28.2347Z" fill="black"/>
              <path d="M18.4981 6.92969C22.3128 6.92784 25.4652 10.0358 25.493 13.8283C25.5207 17.7059 22.3369 20.8916 18.45 20.8731C14.6446 20.8546 11.4866 17.6985 11.4996 13.9301C11.5144 10.0784 14.6575 6.93339 18.4963 6.92969H18.4981Z" fill="black"/>
              </g>
              <defs>
              <clipPath id="clip0_1297_925">
              <rect width="37" height="37" fill="#000"/>
              </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>
    </div>

    <section id="products" class="page-section" v-if="$store.state.orderStep == 'products'">
      <div class="main">
        <Products></Products>
      </div>
      <div class="sidebar">
        <div class="cart mb-3">
          <Cart></Cart>
        </div>
      </div>
    </section>

    <section id="cart" v-if="$store.state.orderStep == 'cart'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-8 mx-auto">
            <!-- <div class="white-box box-shadow white-bg font-weight-bold text-uppercase p-3 text-center mx-auto w-100 mb-3">
              PLEASE ALLOW 1-3 DAYS FOR ONLINE ORDER PROCESSING
            </div> -->
            <Cart></Cart>
          </div>
        </div>
      </div>
    </section>

    <section id="view-account" v-if="$store.state.orderStep == 'view-account'">
      <ViewAccount></ViewAccount>
    </section>

    <section id="account" v-if="$store.state.orderStep == 'account'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <Account></Account>
          </div>
        </div>
      </div>
    </section>

    <section id="shipping" v-if="$store.state.orderStep == 'shipping'">
      <div class="container-fluid">
        <div class="row" v-if="shippingNotice != null && shippingNotice != false">
          <div class="col-12">
            <section class="filter-bar red-links mb-4">
              <h5 class="text-center font-weight-bold mb-0" style="
    background: #000;
    color: #fff;
    padding: 1rem;
    width: 100%;
">{{ shippingNotice }}</h5>
            </section>
          </div>
        </div>
        
        <div class="row">
          <div class="col-12 col-lg-8 col-xl-9 mb-3 mb-lg-0">
            <Shipping></Shipping>
          </div>
          <div class="col-12 col-lg-4 col-xl-3">
            <Cart></Cart>
          </div>
        </div>
      </div>
    </section>

    <section id="billing" v-if="$store.state.orderStep == 'billing'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-8 col-xl-9 mb-3 mb-lg-0">
            <Billing></Billing>
          </div>
          <div class="col-12 col-lg-4 col-xl-3">
            <Cart></Cart>
          </div>
        </div>
      </div>
    </section>

    <section id="review" v-if="$store.state.orderStep == 'review'">
      <ReviewOrder></ReviewOrder>
    </section>

    <section id="confirmation" v-if="$store.state.orderStep == 'confirmation'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-8 mx-auto">
            <section id="shipping-review" class="box-shadow red-links mb-3">
              <div class="box-header mb-0">Your Order Has Been Placed</div>
              <div class="content p-4 white-bg">
                <p class="mb-0">Thank you for your order. Your order is now processing.</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Products from '@/components/fermentation/Products'
import Cart from '@/components/fermentation/Cart'
import Shipping from '@/components/fermentation/Shipping'
import Account from '@/components/fermentation/Account'
import Billing from '@/components/fermentation/Billing'
import ViewAccount from '@/components/fermentation/ViewAccount'
import ReviewOrder from '@/components/fermentation/ReviewOrder'
import MobileCart from '@/components/fermentation/MobileCart'
import Cookies from 'js-cookie'
import Api from '@/models/Api.js'

export default {
  name: 'FermentationProducts',
  components: {
    Products,
    Cart,
    Shipping,
    Account,
    Billing,
    ViewAccount,
    ReviewOrder,
    MobileCart
  },
  data() {
    return {
      shippingNotice: null
    };
  },
  methods: {
    prevStep() {
      this.$store.commit('prevStep');
    },
    changeOrderStep(orderStep) {
      if (orderStep != 'review') {
        this.$store.commit('changeOrderStep', orderStep);
      }
    },
    validateToken() {
      if (this.$store.state.userData != null) {
        Api.postAuthFullResponse('validate-token', [], this.$store.state.userData.token).then(response => {
          if (response.data.statusCode == 403 || response.status == 403) {
            console.log('Invalid Token');
            this.$store.commit('logout');
          } else {
            console.log('Valid Token');
          }
          return true;
        }).catch(() => {
          console.log('Invalid Token');
          this.$store.commit('logout');
        });
      }
    },
    getShippingNotice() {
      Api.get('shipping/notice').then(response => {
        this.shippingNotice = response;
      });
    }
  },
  mounted() {
    if (typeof Cookies.get('userData') != 'undefined') {
      this.$store.commit('setUserData', JSON.parse(Cookies.get('userData')));
    }

    this.validateToken();

    setInterval(() => { this.validateToken(); }, 60000);

    window.onpopstate = (event) => { console.log(event); this.prevStep(); return false; };

    this.$store.commit('setOrderUrl', window.location.href);

    this.getShippingNotice();

    this.$store.commit('restoreProductDiscountPrices');

    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);

    if (params.has("discount-code")) {
      this.$store.commit('addDiscountCode', params.get("discount-code"));
    }
  },
  created() {
    let hash = location.hash.replace("#", "");
    if (hash == 'account') {
      this.$store.commit('changeOrderStep', 'account');
    }

    if ((this.$store.state.cartLastUpdated + 10800000) < Date.now()) {
      this.$store.commit("resetCart");
    }
  }
}
</script>