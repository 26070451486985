<template>
  <div id="products">
    <div class="product-table-no-vue">
      <div class="tab-panels">
        <div class="tab-panel" v-if="currentTab == 'purees'">
          <div class="table-header w-100">
            <!--<div class="col image"></div>-->
            <div class="col product">Product</div>
            <div class="col brix">Brix</div>
            <div class="col intensity">pH</div>
            <div class="col add text-center"></div>
          </div>
          <div class="tab-panels">
            <div class="table-row flex-column w-100" v-bind:class="product.post_name" v-for="product in purees" v-bind:key="product.post_title" :ref="'product-'+product.post_title">
              <div class="top w-100">
                <!-- <div class="col image cursor-pointer" @click="setPopup(product['ID'])"><img v-if="product.image" :src="product.image" :alt="product.post_title" /></div> -->
                <div class="col product cursor-pointer" @click="setPopup(product['ID'])">{{ product.post_title }}</div>
                <div class="popup fermentation-popup" v-if="popupId == product['ID']">
                  <a class="close" @click="setPopup(null)"><i class="far fa-times"></i></a>
                  <img v-if="product.image" :src="product.image" :alt="product.post_title" />
                  <div class="content">
                    <h4>{{ product.post_title }}</h4>
                    <p class="itensity font-weight-bold">Intensity: <span class="red">{{ product.intensity }}</span></p>
                    <div class="description" v-html="product.description"></div>
                  </div>
                </div>
                <div class="col brix">{{ product.brix }}</div>
                <div class="col intensity">{{ product.ph }}</div>
                <div class="col text-center"><button class="button white expand-product" @click="toggleProduct(product['ID'])">Order</button></div>
              </div>
              <div class="bottom" v-bind:class="{ hidden: expandedProduct != product['ID'] }">
                <div class="col swatch" v-if="product.color">
                  <div class="color-swatch" :style="'background:'+product.color"></div>
                </div>
                <div class="col description">
                  <h5>Description</h5>
                  <div class="content text-1" v-html="product.description">
                  </div>
                  <a class="underline-link d-block" href="/fermentation#sizes-and-formats" target="_blank">Click Here To Learn About Sizes and Formats</a>
                  <h5 v-if="product.release_date != null && product.release_date != ''">Release Date</h5>
                  <div class="content text-1" v-html="product.release_date" v-if="product.release_date != null && product.release_date != ''">
                  </div>
                </div>
                <div class="col options" v-if="product.out_of_stock != true">
                  <div v-for="key in Object.keys(product.prices)" v-bind:key="key">
                    <div class="option-row mb-3" v-bind:class="'type-'+key" v-if="!key.includes('Original') && product.prices[key] != '' && product[key+'_out_of_stock'] != true">
                      <div class="w-75">
                        <div class="sohne-breit text-uppercase font-weight-bold" v-if="key == 'box'">42lb Bag in Box</div>
                        <div class="sohne-breit text-uppercase font-weight-bold" v-if="key == 'drum'">
                          <span v-if="product.post_title == 'Plum'">441</span>
                          <span v-else>425</span>
                          lb Drum
                        </div>
                        <div class="sohne-breit text-uppercase font-weight-bold" v-if="key == 'tote'">{{ key }}</div>
                        <div class="sohne-breit text-uppercase font-weight-bold" v-if="key == 'pouch'">1.5L Pouches<br>(case of 6)</div>
                        <div class="sub" v-if="key != 'pouch'">${{ product.prices[key] }} / {{key}}</div>
                        <div class="sub" v-if="key == 'pouch'">${{ product.prices[key] }} / 6 pouches</div>
                      </div>
                      <div class="w-25 text-center"><input type="number" class="w-100 qty" placeholder="QTY" v-model="addQty[key]" min="1" /></div>
                    </div>
                    <div class="option-row mb-2" v-bind:class="'type-'+key" v-if="product.prices[key] != '' && product[key+'_out_of_stock'] == true">
                      <div class="w-75">
                        <div class="sohne-breit text-uppercase font-weight-bold">{{ key }} Out Of Stock</div>
                        <div class="sub" v-if="key != 'pouch'">${{ product.prices[key] }} / {{key}}</div>
                        <div class="sub" v-if="key == 'pouch'">${{ product.prices[key] }} / 6 pouches</div>
                      </div>
                      <div class="w-25 text-center">
                        <a class="get-notified font-weight-bold" @click="addNotify(product['ID'], key)">Get Notified When<br>Back In Stock</a>
                      </div>
                    </div>
                  </div>
                  <div class="w-100">
                    <a class="button add-to-cart red-bg white mb-2 w-100" @click="addToCart(product)">ADD TO CART</a>
                  </div>
                </div>
                <div class="col options text-center" v-else>
                  <strong>This item is currently out of stock.</strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-panel" v-if="currentTab == 'organicPurees'">
          <div class="table-header w-100">
            <!-- <div class="col image"></div> -->
            <div class="col product">Product</div>
            <div class="col brix">Brix</div>
            <div class="col intensity">pH</div>
            <div class="col add text-center"></div>
          </div>
          <div class="tab-panels">
            <div class="table-row flex-column w-100" v-bind:class="product.post_name" v-for="product in organicPurees" v-bind:key="product.post_title" :ref="'product-'+product.post_title">
              <div class="top w-100">
                <!-- <div class="col image cursor-pointer" @click="setPopup(product['ID'])"><img v-if="product.image" :src="product.image" :alt="product.post_title" /></div> -->
                <div class="col product cursor-pointer" @click="setPopup(product['ID'])">{{ product.post_title }}</div>
                <div class="popup fermentation-popup" v-if="popupId == product['ID']">
                  <a class="close" @click="setPopup(null)"><i class="far fa-times"></i></a>
                  <img v-if="product.image" :src="product.image" :alt="product.post_title" />
                  <div class="content">
                    <h4>{{ product.post_title }}</h4>
                    <p class="itensity font-weight-bold">Intensity: <span class="red">{{ product.intensity }}</span></p>
                    <div class="description" v-html="product.description"></div>
                  </div>
                </div>
                <div class="col brix">{{ product.brix }}</div>
                <div class="col intensity">{{ product.ph }}</div>
                <div class="col text-center"><button class="button red-bg white expand-product" @click="toggleProduct(product['ID'])">Order</button></div>
              </div>
              <div class="bottom" v-bind:class="{ hidden: expandedProduct != product['ID'] }">
                <div class="col swatch" v-if="product.color">
                  <div class="color-swatch" :style="'background:'+product.color"></div>
                </div>
                <div class="col description">
                  <h5>Description</h5>
                  <div class="content text-1" v-html="product.description">
                  </div>
                  <a class="underline-link d-block" href="/fermentation/fermentation-products/sizes-formats-shipping/" target="_blank">Click Here To Learn About Sizes and Formats</a>
                  <h5 v-if="product.release_date != null && product.release_date != ''">Release Date</h5>
                  <div class="content text-1" v-html="product.release_date" v-if="product.release_date != null && product.release_date != ''">
                  </div>
                </div>
                <div class="col options" v-if="product.out_of_stock != true">
                  <div v-for="key in Object.keys(product.prices)" v-bind:key="key">
                    <div class="option-row mb-3" v-bind:class="'type-'+key" v-if="!key.includes('Original') && product.prices[key] != '' && product[key+'_out_of_stock'] != true">
                      <div class="w-75">
                        <div class="sohne-breit text-uppercase font-weight-bold" v-if="key == 'box'">42lb Bag in Box</div>
                        <div class="sohne-breit text-uppercase font-weight-bold" v-if="key == 'drum'">
                          <span v-if="product.post_title == 'Plum'">441</span>
                          <span v-else>425</span>
                          lb Drum
                        </div>
                        <div class="sohne-breit text-uppercase font-weight-bold" v-if="key == 'tote'">{{ key }}</div>
                        <div class="sohne-breit text-uppercase font-weight-bold" v-if="key == 'pouch'">1.5L Pouches<br>(case of 6)</div>
                        <div class="sub" v-if="key != 'pouch'">${{ product.prices[key] }} / {{key}}</div>
                        <div class="sub" v-if="key == 'pouch'">${{ product.prices[key] }} / 6 pouches</div>
                      </div>
                      <div class="w-25 text-center"><input type="number" class="w-100 qty" placeholder="QTY" v-model="addQty[key]" min="1" /></div>
                    </div>
                    <div class="option-row mb-2" v-bind:class="'type-'+key" v-if="product.prices[key] != '' && product[key+'_out_of_stock'] == true">
                      <div class="w-75">
                        <div class="sohne-breit text-uppercase font-weight-bold">{{ key }} Out Of Stock</div>
                        <div class="sub" v-if="key != 'pouch'">${{ product.prices[key] }} / {{key}}</div>
                        <div class="sub" v-if="key == 'pouch'">${{ product.prices[key] }} / 6 pouches</div>
                      </div>
                      <div class="w-25 text-center">
                        <a class="get-notified font-weight-bold" @click="addNotify(product['ID'], key)">Get Notified When<br>Back In Stock</a>
                      </div>
                    </div>
                  </div>
                  <div class="w-100">
                    <a class="button add-to-cart mb-3 w-100" @click="addToCart(product)">ADD TO CART</a>
                  </div>
                </div>
                <div class="col options text-center" v-else>
                  <strong>This item is currently out of stock.</strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-panel" v-if="currentTab == 'concentrates'">
          <div class="table-header w-100">
            <!-- <div class="col image"></div> -->
            <div class="col product">Product</div>
            <div class="col brix">Brix</div>
            <div class="col intensity">pH</div>
            <div class="col add text-center"></div>
          </div>
          <div class="tab-panels">
            <div class="table-row flex-column w-100" v-bind:class="product.post_name" v-for="product in concentrates" v-bind:key="product.post_title" :ref="'product-'+product.post_title">
              <div class="top w-100">
                <!-- <div class="col image cursor-pointer" @click="setPopup(product['ID'])"><img v-if="product.image" :src="product.image" :alt="product.post_title" /></div> -->
                <div class="col product cursor-pointer" @click="setPopup(product['ID'])">{{ product.post_title }}</div>
                <div class="popup fermentation-popup" v-if="popupId == product['ID']">
                  <a class="close" @click="setPopup(null)"><i class="far fa-times"></i></a>
                  <img v-if="product.image" :src="product.image" :alt="product.post_title" />
                  <div class="content">
                    <h4>{{ product.post_title }}</h4>
                    <p class="itensity font-weight-bold">Intensity: <span class="red">{{ product.intensity }}</span></p>
                    <div class="description" v-html="product.description"></div>
                  </div>
                </div>
                <div class="col brix">{{ product.brix }}</div>
                <div class="col intensity">{{ product.ph }}</div>
                <div class="col text-center"><button class="button red-bg white expand-product" @click="toggleProduct(product['ID'])">Order</button></div>
              </div>
              <div class="bottom" v-bind:class="{ hidden: expandedProduct != product['ID'] }">
                <div class="col swatch" v-if="product.color">
                  <div class="color-swatch" :style="'background:'+product.color"></div>
                </div>
                <div class="col description">
                  <h5>Description</h5>
                  <div class="content text-1" v-html="product.description">
                  </div>
                  <a class="underline-link d-block" href="/fermentation/fermentation-products/sizes-formats-shipping/" target="_blank">Click Here To Learn About Sizes and Formats</a>
                  <h5 v-if="product.release_date != null && product.release_date != ''">Release Date</h5>
                  <div class="content text-1" v-html="product.release_date" v-if="product.release_date != null && product.release_date != ''">
                  </div>
                </div>
                <div class="col options" v-if="product.out_of_stock != true">
                  <div v-for="key in Object.keys(product.prices)" v-bind:key="key">
                    <div class="option-row mb-3" v-bind:class="'type-'+key" v-if="!key.includes('Original') && product.prices[key] != '' && product[key+'_out_of_stock'] != true">
                      <div class="w-75">
                        <div class="sohne-breit text-uppercase font-weight-bold" v-if="key == 'box'">5 Gallon Bag in Box</div>
                        <div class="sohne-breit text-uppercase font-weight-bold" v-if="key == 'drum'">
                          <span v-if="product.post_title == 'Plum'">441</span>
                          <span v-else>425</span>
                          lb Drum
                        </div>
                        <div class="sohne-breit text-uppercase font-weight-bold" v-if="key == 'tote'">{{ key }}</div>
                        <div class="sohne-breit text-uppercase font-weight-bold" v-if="key == 'pouch'">1.5L Pouches<br>(case of 6)</div>
                        <div class="sub" v-if="key != 'pouch'">${{ product.prices[key] }} / {{key}}</div>
                        <div class="sub" v-if="key == 'pouch'">${{ product.prices[key] }} / 6 pouches</div>
                      </div>
                      <div class="w-25 text-center"><input type="number" class="w-100 qty" placeholder="QTY" v-model="addQty[key]" min="1" /></div>
                    </div>
                    <div class="option-row mb-2" v-bind:class="'type-'+key" v-if="product.prices[key] != '' && product[key+'_out_of_stock'] == true">
                      <div class="w-75">
                        <div class="sohne-breit text-uppercase font-weight-bold">{{ key }} Out Of Stock</div>
                        <div class="sub" v-if="key != 'pouch'">${{ product.prices[key] }} / {{key}}</div>
                        <div class="sub" v-if="key == 'pouch'">${{ product.prices[key] }} / 6 pouches</div>
                      </div>
                      <div class="w-25 text-center">
                        <a class="get-notified font-weight-bold" @click="addNotify(product['ID'], key)">Get Notified When<br>Back In Stock</a>
                      </div>
                    </div>
                  </div>
                  <div class="w-100">
                    <a class="button add-to-cart red-bg white mb-2 w-100" @click="addToCart(product)">ADD TO CART</a>
                  </div>
                </div>
                <div class="col options text-center" v-else>
                  <strong>This item is currently out of stock.</strong>
                </div>
              </div>
            </div>
            <div class="table-row flex-column w-100" v-bind:class="product.post_name" v-for="product in lemonades" v-bind:key="product.post_title" :ref="'product-'+product.post_title">
              <div class="top w-100">
                <!-- <div class="col image cursor-pointer" @click="setPopup(product['ID'])"><img v-if="product.image" :src="product.image" :alt="product.post_title" /></div> -->
                <div class="col product cursor-pointer" @click="setPopup(product['ID'])">{{ product.post_title }}</div>
                <div class="col brix"></div>
                <div class="col price intensity font-weight-500">${{ product.price }}</div>
                <div class="popup fermentation-popup" v-if="popupId == product['ID']">
                  <a class="close" @click="setPopup(null)"><i class="far fa-times"></i></a>
                  <img v-if="product.image" :src="product.image" :alt="product.post_title" />
                  <div class="content">
                    <h4>{{ product.post_title }}</h4>
                    <p class="itensity font-weight-bold">Intensity: <span class="red">{{ product.intensity }}</span></p>
                    <div class="description" v-html="product.description"></div>
                  </div>
                </div>
                <div class="col text-center"><button class="button red-bg white expand-product" @click="toggleProduct(product['ID'])">Order</button></div>
              </div>
              <div class="bottom" v-bind:class="{ hidden: expandedProduct != product['ID'] }">
                <div class="col swatch" v-if="product.color">
                  <div class="color-swatch" :style="'background:'+product.color"></div>
                </div>
                <div class="col description">
                  <h5>Description</h5>
                  <div class="content text-1" v-html="product.description">
                  </div>
                  <h5 v-if="product.release_date != null && product.release_date != ''">Release Date</h5>
                  <div class="content text-1" v-html="product.release_date" v-if="product.release_date != null && product.release_date != ''">
                  </div>
                </div>
                <div class="col options" v-if="product.out_of_stock != true">
                  <div class="w-100">
                    <a class="button add-to-cart red-bg white mb-2 w-100" @click="addToCart(product)">ADD TO CART</a>
                  </div>
                </div>
                <div class="col options text-center" v-else>
                  <strong>This item is currently out of stock.</strong>
                </div>
              </div>
            </div>
          </div>
        </div>        

        <div class="tab-panel" v-if="currentTab == 'gear'">
          <div class="table-header w-100">
            <!-- <div class="col image"></div> -->
            <div class="col product">Product</div>
            <div class="col price text-center"></div>
            <div class="col text-center"></div>
            <div class="col add text-center"></div>
          </div>
          <div class="tab-panels">
            <div class="table-row flex-column w-100" v-bind:class="product.post_name" v-for="product in lemonades" v-bind:key="product.post_title" :ref="'product-'+product.post_title">
              <div class="top w-100">
                <!-- <div class="col image cursor-pointer" @click="setPopup(product['ID'])"><img v-if="product.image" :src="product.image" :alt="product.post_title" /></div> -->
                <div class="col product cursor-pointer" @click="setPopup(product['ID'])">{{ product.post_title }}</div>
                <div class="col price text-center font-weight-500">${{ product.price }}</div>
                <div class="popup fermentation-popup" v-if="popupId == product['ID']">
                  <a class="close" @click="setPopup(null)"><i class="far fa-times"></i></a>
                  <img v-if="product.image" :src="product.image" :alt="product.post_title" />
                  <div class="content">
                    <h4>{{ product.post_title }}</h4>
                    <p class="itensity font-weight-bold">Intensity: <span class="red">{{ product.intensity }}</span></p>
                    <div class="description" v-html="product.description"></div>
                  </div>
                </div>
                <div class="col text-center"><button class="button red-bg white expand-product" @click="toggleProduct(product['ID'])">Order</button></div>
              </div>
              <div class="bottom" v-bind:class="{ hidden: expandedProduct != product['ID'] }">
                <div class="col swatch" v-if="product.color">
                  <div class="color-swatch" :style="'background:'+product.color"></div>
                </div>
                <div class="col description">
                  <h5>Description</h5>
                  <div class="content text-1" v-html="product.description">
                  </div>
                  <h5 v-if="product.release_date != null && product.release_date != ''">Release Date</h5>
                  <div class="content text-1" v-html="product.release_date" v-if="product.release_date != null && product.release_date != ''">
                  </div>
                </div>
                <div class="col options" v-if="product.out_of_stock != true">
                  <div class="w-100">
                    <a class="button add-to-cart red-bg white mb-2 w-100" @click="addToCart(product)">ADD TO CART</a>
                  </div>
                </div>
                <div class="col options text-center" v-else>
                  <strong>This item is currently out of stock.</strong>
                </div>
              </div>
            </div>
            <div class="table-row gear-item-row w-100" v-for="gearItem in gear" v-if="gearItem.post_type == 'gear'" v-bind:key="gearItem.post_title">
              <!-- <div class="col image cursor-pointer" v-if="gearItem.image != null"><img :src="gearItem.image" :alt="gearItem.post_title" /></div> -->
              <div class="col product"><span class="cursor-pointer">{{ gearItem.post_title }}</span><span class="description d-block" v-if="gearItem.description != false" v-html="gearItem.description"></span></div>
              <div class="col price text-center font-weight-500">${{ gearItem.price }}</div>
              <div class="col text-center font-weight-500" v-if="gearItem['ID'] == shirtGearId">
                <select v-model="size">
                  <!-- <option value="Small">Small</option>
                  <option value="Medium">Medium</option> -->
                  <option value="Large">Large</option>
                  <option value="Extra Large">Extra Large</option>
                </select>
              </div>
              <div class="col text-center"><button class="button red-bg white" @click="addToCart(gearItem)">Add To Cart</button></div>
            </div>
          </div>
        </div>

        <div class="tab-panel" v-if="currentTab == 'samples'">
          <div class="table-header w-100 d-none">
            <div class="col text-center">Please Only Request 4 Samples Per Order</div>
          </div>
          <div class="table-header w-100">
            <!-- <div class="col image"></div> -->
            <div class="col product">Product</div>
            <div class="col brix">Brix</div>
            <div class="col intensity">pH</div>
            <div class="col add text-center"></div>
          </div>
          <div class="tab-panels">
            <div class="table-row flex-column w-100" v-bind:class="product.post_name" v-for="product in samples" v-bind:key="product.post_title" :ref="'product-'+product.post_title">
              <div class="top w-100">
                <!-- <div class="col image cursor-pointer" @click="setPopup(product['ID'])"><img :src="product.image" :alt="product.post_title" /></div> -->
                <div class="col product cursor-pointer" @click="setPopup(product['ID'])">{{ product.post_title }} Sample</div>
                <div class="popup fermentation-popup" v-if="popupId == product['ID']">
                  <a class="close" @click="setPopup(null)"><i class="far fa-times"></i></a>
                  <img :src="product.image" :alt="product.post_title" />
                  <div class="content">
                    <h4>{{ product.post_title }}</h4>
                    <p class="itensity font-weight-bold">Intensity: <span class="red">{{ product.intensity }}</span></p>
                    <div class="description" v-html="product.description"></div>
                  </div>
                </div>
                <div class="col brix">{{ product.brix }}</div>
                <div class="col intensity">{{ product.ph }}</div>
                <div class="col text-center"><button class="button red-bg white expand-product" @click="toggleProduct(product['ID'])">Order</button></div>
              </div>
              <div class="bottom" v-bind:class="{ hidden: expandedProduct != product['ID'] }">
                <div class="col description">
                  <h5>Description</h5>
                  <div class="content text-1" v-html="product.description">
                  </div>
                  <h5 v-if="product.release_date != null && product.release_date != ''">Release Date</h5>
                  <div class="content text-1" v-html="product.release_date" v-if="product.release_date != null && product.release_date != ''">
                  </div>
                </div>
                <div class="col options" v-if="product.out_of_stock != true">
                  <div>
                    <div class="option-row mb-3" v-bind:class="'type-box'">
                      <div class="w-25 text-center text-uppercase font-weight-bold">Sample</div>
                      <div class="w-25 text-center"><input type="number" class="w-100 qty d-none" placeholder="QTY" v-model="addQty['sample']" min="1" /></div>
                      <div class="w-50 text-center font-weight-bold">Free</div>
                    </div>
                  </div>
                  <div class="w-100">
                    <a class="button add-to-cart red-bg white mb-3 w-100" @click="addToCart({ ...product, sample: true })">ADD TO CART</a>
                  </div>
                </div>
                <div class="col options text-center" v-else>
                  <strong>This item is currently out of stock.</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/models/Api.js'
import { queryString } from '@/helpers.js'
import { validatePositiveQtys } from '@/helpers.js'

export default {
  name: 'Products',
  data() {
    return {
      products: null,
      organicProducts: null,
      gear: null,
      popupId: null,
      addQty: {},
      priceValues: {},
      totalValues: null,
      expandedProduct: null,
      shirtGearId: 3312,
      size: 'Medium'
    };
  },
  computed: {
    samples() {
      let samples = [];
      if (this.products != null) {
        this.products.forEach(product => {
          if (product.samples_out_of_stock != true && product.post_type != 'concentrate' && product.post_type != 'lemonade') {
            samples.push(product);
          }
        });
      }
      return samples;
    },
    purees() {
      if (this.products != null) {
        return this.products.filter(product => product.puree_type == 'regular');
      }
      return this.products;
    },
    organicPurees() {
      if (this.products != null) {
        return this.products.filter(product => product.puree_type == 'organic');
      }
      return [];
    },
    concentrates() {
      if (this.products != null) {
        return this.products.filter(product => product.post_type == 'concentrate');
      }
      return [];
    },
    lemonades() {
      if (this.products != null) {
        return this.products.filter(product => product.post_type == 'lemonade');
      }
      return [];
    },
    currentTab: {
      get() {
        return this.$store.state.productsCurrentTab;
      },
      set(newValue) {
        this.$store.commit('setProductsCurrentTab', newValue);
      }
    }
  },
  methods: {
    getProducts(queryString) {
      let url = 'purees';
      if (queryString) {
        url += queryString;
      }
      Api.get(url).then(response => {
        this.products = response;
      });
    },
    getGear(queryString) {
      let url = 'gear';
      if (queryString) {
        url += queryString;
      }
      Api.get(url).then(response => {
        this.gear = response;
      });
    },
    getFilteredProducts() {
      this.getProducts(queryString({ type: (this.currentTab ? this.currentTab : '') }));
    },
    setCurrentTab(tab) {
      this.$store.commit('setProductsCurrentTab', tab);
    },
    setPopup(id) {
      this.popupId = id;
    },
    resetInputs() {
      this.addQty = {};
    },
    addToCart(product) {
      this.$store.commit('setPaymentMethod', 'Credit Card');
      if (product.post_type == 'gear') {
        this.addQty['gear'] = 1;
      }
      if (product.post_type == 'lemonade') {
        this.addQty['lemonade'] = 1;
      }
      if ('sample' in product && product.sample == true) {
        if (this.$store.getters.samplesInCart + 1 > 4) {
          this.$overlay(true, 'Sorry, you can only order 4 samples per order.');
          setTimeout(() => { this.$overlay(false); }, 1500);
          return false;
        }
        this.addQty['sample'] = 1;
        product.post_type = 'sample';
        product.prices['sample'] = 0.00;
      }
      if (Object.keys(this.addQty).length != 0) {
        Object.keys(this.addQty).forEach(key => {
          if (product.post_type == 'puree' && this.addQty[key] > parseInt(product[key+'_stock'])) {
            this.$overlay(true, 'We do not have enough stock of '+product.post_title+' ('+key+') to add to your cart. ('+product[key+'_stock']+' available to order)', 3000);
            return;
          }
          let cartItem = { qty: this.addQty[key], type: key, ...product };
          if (product.ID == this.shirtGearId && this.size != '') {
            cartItem.notes = 'Size: '+this.size;
          }
          this.$store.commit('addToCart', cartItem);
          this.$overlay(true, 'Item added to cart!');
        });
        setTimeout(() => { this.$overlay(false); }, 1500);
        this.$store.commit('trackHubspotEvent', { id: 'Added Item To Cart: '+product.post_title });
        this.resetInputs();
      } else {
        this.$overlay(true, 'Choose a size and quantity first.');
        setTimeout(() => { this.$overlay(false); }, 1500);
      }
    },
    toggleProduct(id) {
      if (this.expandedProduct == id) {
        this.expandedProduct = null;
      } else {
        this.expandedProduct = id;
        this.addQty = {};
      }
    },
    getProductPricePer(product) {
      if (product['ID'] in this.typeValues && typeof product.prices[this.typeValues[product['ID']]] != 'undefined') {
        return '$'+product.prices[this.typeValues[product['ID']]] + ' / ' + this.typeValues[product['ID']];
      } else {
        return '';
      }
    },
    getTotalPrice(product) {
      let total = parseFloat(product.prices[this.typeValues[product['ID']]] * this.qtyValues[product['ID']]).toFixed(2);
      if (total != 'NaN') {
        return '$'+total;
      } else {
        return '$'+0.00;
      }
    },
    clearCartIfExpired() {
      if (Date.now() > parseInt(this.$store.state.cartLastUpdated) + 43200000) {
        this.$store.commit('resetCart');
      }
    },
    addNotify(itemId, type) {
      if (this.$store.state.userData == null) {
        this.$store.commit('changeOrderStep', 'account');
        return;
      }
      let notify = {
        customer_id: this.$store.state.userData.id,
        type: type,
        item_id: itemId
      };
      Api.postAuth('stock/notify', notify, this.$store.state.userData.token).then(response => {
        if (response) {
          this.$overlay(true, 'Great! We will let you know when it comes back in stock.', 3000);
        }
      });
    },
    checkForActions() {
      let uri = window.location.search;
      let params = new URLSearchParams(uri);

      if (params.has("action") && params.get("action") == 'add-to-cart') {
        const qty = {};
        for (let [key, value] of params.entries()) {
          if (key.startsWith('qty[')) {
            const property = key.substring(4, key.length - 1);
            qty[property] = value ? parseInt(value) : 0;
          }
        }
        let product = this.products.find(product => product.ID == params.get('product-id'));
        if (Object.keys(qty).length != 0) {
          Object.keys(qty).forEach(key => {
            if (product.post_type == 'puree' && qty[key] > parseInt(product[key+'_stock'])) {
              this.$overlay(true, 'We do not have enough stock of '+product.post_title+' ('+key+') to add to your cart. ('+product[key+'_stock']+' available to order)', 3000);
              return;
            }
            let cartItem = { qty: qty[key], type: key, ...product };
            this.$store.commit('addToCart', cartItem);
            this.$overlay(true, 'Item added to cart!');
          });
          setTimeout(() => { this.$overlay(false); }, 1500);
          this.$store.commit('trackHubspotEvent', { id: 'Added Item To Cart: '+product.post_title });
        }
      }
    }
  },
  mounted() {
    if (window.location.hash != '' && window.location.hash != '#products') {
      setTimeout(() => {
        let productTitle = window.location.hash.replace("#", "");
        productTitle = decodeURI(productTitle);
        let foundProduct = this.products.find(element => element.post_title == productTitle);
        if (foundProduct != undefined) {
          this.toggleProduct(foundProduct['ID']);
          this.$refs['product-'+productTitle][0].scrollIntoView({ behavior: 'smooth' });
        }
      }, 1500);
    }

    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    
    if (params.has('tab')) {
      this.setCurrentTab(params.get('tab'));
    }

    setTimeout(() => { this.checkForActions(); }, 1500);
  },
  created() {
    this.getFilteredProducts();
    this.getGear();
    this.qtyValues = {};
    this.typeValues = {};
    this.totalValues = {};
    this.addQty = {};
    validatePositiveQtys();

    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    
    // if (params.has('tab')) {
    //   this.setCurrentTab(params.get('tab'));
    // }

    let product = params.get("product");
    if (product) {
      this.setPopup(product);
    }
    this.clearCartIfExpired();
  }
}
</script>

<style lang="scss">

</style>