<template>
  <section id="mobile-cart" class="d-lg-none align-items-center">
    <div class="login">
      <div class="text-center" v-if="$store.state.userData != null">
         <a class="text-0" @click="$store.commit('changeOrderStep', 'view-account')"><i class="fal fa-user"></i></a>
      </div>
      <div class="text-center" v-else>
        <a class="login" @click="$store.commit('changeOrderStep', 'account')"><i class="fal fa-user"></i> <span>Sign In</span></a>
      </div>
    </div>
    <div class="next text-center red-links">
      <a class="button" v-if="$store.state.orderStep == 'products' && $store.state.cartItems.length > 0" @click="$store.commit('changeOrderStep', 'cart')">View Cart</a>
      <a class="button" v-if="$store.state.orderStep == 'cart' && $store.state.userData != null" @click="$store.commit('changeOrderStep', 'shipping')">Checkout</a>
      <a class="button" v-if="$store.state.orderStep == 'cart' && $store.state.userData == null" @click="$store.commit('changeOrderStep', 'account')">Checkout</a>
      <a class="button" v-if="$store.state.orderStep == 'shipping' && $store.state.orderInfo.shippingMethod != null" @click="$store.commit('changeOrderStep', 'billing')">Continue</a>
      <a class="button" v-if="$store.state.orderStep == 'billing' && $store.getters.isOrderComplete && financingAllowed" @click="$store.commit('changeOrderStep', 'review')">Review Order</a>
    </div>
    <div class="cart text-center">
      <span class="total"><a @click="$store.commit('changeOrderStep', 'cart')">${{ $store.getters.cartFinalTotal }}</a></span> <a @click="$store.commit('changeOrderStep', 'cart')"><i class="fal fa-shopping-cart"></i><span class="count">{{ $store.state.cartItems.length }}</span></a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MobileCart',
  data() {
    return {

    };
  },
  methods: {
    financingAllowed() {
      if (this.paymentMethod == 'Invoice' && this.$store.state.userData.financing != true) {
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>